import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

export default function SmallForm({songId, onSubmit = () => {}}) {
  let [title, setTitle] = useState(`${(new Date()).toISOString()}`)

  return(
    <div className='input-group'>
      <input
        className='form-control'
        onChange={(event) => setTitle(event.target.value)}
        placeholder='New playlist name'
        type="text"
        value={title}
      />
      <button
        className="btn btn-outline-secondary"
        onClick={onSubmit(title)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  )
}

