import React, { useState } from 'react'
import { NavLink, useNavigate } from "react-router-dom"
import { addDoc, collection } from 'firebase/firestore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { RowBig } from '../../utils/Utils';
import { useFirestore, useUser } from 'reactfire';

const DEFAULT_AUTHOR = {
  name:'',
  description: ''
}

function New(props) {

  /* Auth check */
  const {status: userStatus, data: currentUser} = useUser()
  let navigate = useNavigate()
  if (userStatus!== 'loading' && currentUser === null) navigate('/')
  /* /Auth check */

  const [data, setData] = useState(DEFAULT_AUTHOR)
  const firestore = useFirestore();
  const collectionRef = collection(firestore, 'authors')

  const changeField = ({field, value}) => {
    let newData = Object.assign({}, data)
    newData[field] = value
    setData(newData)
  }

  if (data.id){
    navigate(`/authors/${data.id}/edit`)
  }

  const onFieldChange = (field) => (event) => changeField({field, value: event.target.value})

  const saveData = () => {
    if (data.id) return false

    addDoc(collectionRef, {...data, createdBy: currentUser.uid}).then((docRef) => {
      setData({...data, id: docRef.id})
    })
  }

  return (
    <>
      <RowBig>
        <div className="col">
          <h2>
            <NavLink to="/authors" className="me-3">
              <FontAwesomeIcon icon={faChevronLeft} />
            </NavLink>
            <input
              className="form-control"
              type="text"
              onChange={onFieldChange('name')}
              value={data.name}
              placeholder="Name..."
            />
          </h2>
          <div>
            <textarea
              className="form-control"
              onChange={onFieldChange('description')}
              placeholder="Description..."
              value={data.description}
            />
          </div>
        </div>
      </RowBig>
      <RowBig>
        <button
          className="btn btn-primary btn-block"
          onClick={saveData}
        >
          Save
        </button>
      </RowBig>
    </>
  )
}

export default New
