import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

const UserInfo = ({ user, onClick }) => {
  return(
    <div className="alert alert-info">
      <p>
        <img
          alt={user.email}
          className="img-thumbnail me-2"
          src={user.photoURL}
          width="32"
        />
        {user.displayName || user.email}
      </p>
      <p>
        <button
          className="btn btn-outline-secondary"
          onClick={onClick}
        >
          <FontAwesomeIcon icon={faSignOutAlt} />
          Log out
        </button>
      </p>
    </div>
  )
}
export default UserInfo

export const UserBar = ({ user }) => {
  return(
    <>
      <img
        alt={user.email}
        className="img-thumbnail me-2"
        src={user.photoURL}
        width="22"
        />
      {user.displayName ? user.displayName.split(' ')[0] : user.email}
    </>
  )
}
