import React, { useEffect, useState } from 'react'

import { getStorage, ref, getDownloadURL, getMetadata } from 'firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconForContentType } from '../utils/Utils';
import { useFileRefs } from '../utils/hooks';

function FilesList({id}) {
  const fileRefs = useFileRefs(id)

  console.log('FilesList', fileRefs)

  return(
    <ul className="list-group">
      {fileRefs.map((item, index) => <Item item={item} key={`file-${index}`} />)}
    </ul>
  )
}

function Item({item}){
  console.log('Item', item)

  const storage = getStorage();
  const fileRef = ref(storage, item)
  const [metadata, setMetadata] = useState({})
  const [url, setUrl] = useState()

  useEffect(() => {
    // console.log('Item useEffect')
    if (fileRef) {

      getMetadata(fileRef).then(metadata => setMetadata(metadata))
      getDownloadURL(fileRef).then(url => setUrl(url))
    } else {
      setUrl('')
      setMetadata({})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (fileRef == null) return null;
  if (url == null) return null;

  console.log('Item render', url, metadata)

  return(
    <li className="list-group-item">
      <a href={url}>
        <FontAwesomeIcon icon={iconForContentType(metadata.contentType)} />
        <span className='ms-1'>{metadata.name || url}</span>
      </a>
    </li>)
}


export default FilesList
