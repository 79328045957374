import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Lyrics from './lyrics/'
import SwitchTranslations from './SwitchTranslations'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

const SongTranslationsEdit = ({ index, translations, onFieldChange, addTranslation, removeTranslation, switchTranslation }) => {
  let data = translations[index || 0]

  return (
    <>
      <SwitchTranslations
        index={index}
        onChange={switchTranslation}
        translations={translations}
      >
          <button
            className="btn btn-outline-secondary"
            onClick={addTranslation}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="d-none d-sm-inline-block ms-1">Add Translation</span>
          </button>
          {index > 0 && <button
            className="btn btn-danger"
            onClick={removeTranslation}
          >
          <FontAwesomeIcon icon={faTrash} />
          <span className="d-none d-sm-inline-block ms-1">Delete Translation</span>
          </button>}
      </SwitchTranslations>
      {data &&
        <Lyrics.Form
          title={data.title || ''}
          body={data.body || ''}
          lang={data.lang}
          onChange={onFieldChange(index)}
        />}
    </>
  )
}
export default SongTranslationsEdit
