import React, { useState, Suspense } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faList, faPlus } from '@fortawesome/free-solid-svg-icons';
import { collection, query, where, doc, updateDoc, addDoc } from 'firebase/firestore';
import { useUser, useFirestore, useFirestoreCollectionData } from 'reactfire'

import SmallForm from './SmallForm'

export default function AddToPlaylist(props) {
  const {data: currentUser} = useUser()
  let collectionRef = collection(useFirestore(), 'playlists')
  let { data: items } = useFirestoreCollectionData(query(collectionRef, where('ownerId', '==', currentUser.uid)), {idField: 'id'})

  let [isOpen, setOpen] = useState(false),
      songId = props.songId,
      transpose = props.transpose,
      translationIndex = props.translationIndex

  const onSubmit = (name) => (event) => {
    let newItem = {
      name, ownerId: currentUser.uid,
      songs: [{...props}]
    }

    const docRef = addDoc(collectionRef, newItem)
    newItem.id = docRef.id
  }

  const onToggle = (id) => () => {
    let item = items.find((i) => i.id === id)
    let songs = item.songs
    let song = songs.find((s) => s.songId === songId)

    const newSong = {
      songId: songId,
      transpose: transpose,
      translationIndex: translationIndex * 1,
    }

    songs = song ?
      songs.filter((s) => s.songId !== songId) :
      [...songs, newSong]

    const docRef = doc(collectionRef, id);
    updateDoc(docRef, {songs: songs})
  }

  // console.log('AddToPlaylist#render', items, songId)

  return(
    <Suspense fallback={'...'}>
      <button
        className='ms-3 btn btn-outline-secondary'
        onClick={() => setOpen(!isOpen)}
      >
        <FontAwesomeIcon icon={faList} />
        <span className="d-none d-sm-inline-block ms-1">Add To Playlist</span>
      </button>

      {isOpen &&
        <div className='border p-1'>
          <ul className='list-group list-group-flush' style={{overflow:'scroll', height: '160px'}}>
            {items.map((item) =>
              <RenderItem
                key={`listitem-${item.id}`}
                songId={songId}
                onToggle={onToggle}
                {...item}
              />)}
          </ul>
          <SmallForm
            onSubmit={onSubmit}
            songId={songId}
          />
        </div>}
    </Suspense>
  )
}

const RenderItem = ({id, name, songs, songId, onToggle}) => {
  let song = songs.find((song) => song.songId === songId)
  let icon = song ? faCheck : faPlus

  return(
    <li
      className='list-group-item d-flex justify-content-between align-items-center pl-0 pr-0'
      onClick={onToggle(id)}
    >
      <span>{name}</span>
      {/* <small className="text-muted">{song ? 'added': 'add'}</small> */}
      <FontAwesomeIcon icon={icon} />
    </li>
  )
}
