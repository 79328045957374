import React from 'react';
// import { useFirestore, useFirestoreCollection } from 'reactfire'
// import { NavLink } from 'react-router-dom'
// import ItemsList from '../components/ItemsList'

function Home() {
  // let [dataAuthors, setAuthors] = useState([])
  // let colAuthors = useFirestore()
  //   .collection('authors')
  //   .limit(10)
  // let snapAuthors = useFirestoreCollection(colAuthors)

  
  // const [authors] = useCollectionData({name: "authors"})

  // useEffect(() => {
  //   setAuthors(Object.assign({}, authors
  //       .docs
  //       .map(doc => Object.assign({id: doc.id}, doc.data()))))
  // }, [authors])

  
  return (
    <div className="row">
      <div className="col text-center">
        Under Construction...
      </div>
    {/*
      <div className="col">
        <ItemsList
          fields={['name']}
          items={authors}
          renderItem={renderItem}
        >
          <h2>
            Authors
          </h2>
        </ItemsList>
      </div>
      <div className="col">
        <p>Welcome, good citizen</p>
      </div>
      <div className="col">
        <p>Welcome, good citizen</p>
      </div>
    */}
    </div>
  )
}

// function returnCollectionData({name}) {
//   let collection = useFirestore()
//     .collection(name)
//     .limit(10)
//   const snap = useFirestoreCollection(collection)
//   const data = snap.docs.map(doc => Object.assign({id: doc.id}, doc.data()))
  
//   return data
// }

// const useCollectionData = (name) => {
//   const [data, setData] = useState([]);

//   let collection = useFirestore()
//     .collection(name)
//     .limit(10)

//   useEffect(() => {
//     const snap = useFirestoreCollection(collection)
//     setData(snap.docs.map(doc => Object.assign({id: doc.id}, doc.data())))
// }, [name]);

//   return [data];
// };

// function renderItem (item, index) {
//   let { query } = this.state // state from ItemsList component
//   return(
//     <NavLink
//       className="list-group-item pl-0 pr-0 d-flex"
//       key={'author-' + index}
//       to={`/authors/${item.id}`}
//     >
//       <span className="flex-grow-1">
//         {item.name}
//       </span>
//     </NavLink>
//   )
// }

export default Home
