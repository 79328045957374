import React from 'react'

import { useUser, useFirestore, useFirestoreCollectionData } from 'reactfire'
import { collection, query, where } from 'firebase/firestore'

import { List as ItemsList } from '../../components/playlist/List'

function PrivatePlaylists ({collectionRef, currentUser}) {
  const {data} = useFirestoreCollectionData(query(collectionRef, 
    where('ownerId', '==', currentUser.uid)), 
    {idField: 'id'})

  return(
    <ItemsList data={data} isOwner={true}>
      <h2>My Playlists</h2>
    </ItemsList>
  )
}

function PublicPlaylists ({collectionRef}) {
  const {data} = useFirestoreCollectionData(query(collectionRef, 
    where('isPublic', '==', true)), 
    {idField: 'id'})

  return(
  <ItemsList data={data} isOwner={false}>
    <h2>Public Playlists</h2>
  </ItemsList>
  )
}

export default function List() {
  const { data: currentUser } = useUser();
  const collectionRef = collection(useFirestore(), 'playlists')

  return (
    <>
      {currentUser && <PrivatePlaylists collectionRef={collectionRef} currentUser={currentUser} />}
      <hr/>
      <PublicPlaylists collectionRef={collectionRef} />
    </>
  )
}
