import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

const ButtonsTranspose = ({onClick, value}) => {
  return(
    <div className="input-group ms-3">
      <button className={`btn btn-outline-secondary ${value < 0 && 'active'}`} onClick={onClick(-1)} >
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <span className="input-group-text">{value > 0 && '+'}{value}</span>
      <button className={`btn btn-outline-secondary ${value > 0 && 'active'}`} onClick={onClick(1)} >
      <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  )
}
export default ButtonsTranspose
