import React, { useState } from 'react'
import { Navigate, useNavigate } from "react-router-dom"

import { collection, orderBy, query, addDoc } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire'

import SongTranslationsEdit from '../../components/SongTranslationsEdit'
import Breadcrumbs from '../../components/Breadcrumbs'
import { RowBig } from '../../utils/Utils'

const DEFAULT_SONG = {
  title:'New song title',
  body: '',
  album: '',
  year: '',
  links: [],
  translations: [
    { title: 'Translation title', body: '', }
  ]
};

const DEFAULT_TRANSLATION = {
  title: '',
  body: '',
  lang: 'EN'
};

export default function New(props) {
  /* Auth check */
  const {status: userStatus, data: currentUser} = useUser()
  let navigate = useNavigate()
  if (userStatus!== 'loading' && currentUser === null) navigate('/')
  /* /Auth check */

  let [translationIndex, setTranslationIndex] = useState(props.translationIndex || 0),
      [data, setData] = useState(DEFAULT_SONG),
      [author, setAuthor] = useState(null)

  const firestore = useFirestore();
  const songsRef = collection(firestore, 'songs')
  const {data: authors} = useFirestoreCollectionData(
    query(
      collection(firestore, 'authors'),
      orderBy('name', 'asc')
    ),
    { idField: 'id' }
  )

  const changeField = ({index, field, value}) => {
    let newData = {}
    if (0 === parseInt(index)) {
      newData = Object.assign({}, data, {[field]: value})
    } else {
      let newTrans = data.translations || []
      newTrans[index - 1][field] = value
      newData = Object.assign({}, data)
      newData.translations = newTrans
    }
    setData(newData)
  }

  const onFieldChange = (index) => (field) => (event) => changeField({index, field, value: event.target.value})

  const onAuthorChange = (event) => {
    let author = authors.find((a) => a.id === event.target.value)
    setAuthor(author)

    let newData = Object.assign({}, data)
    newData.author = ['authors', author.id].join('/')
    setData(newData)
  }

  const addTranslation = () => {
    let newData = Object.assign({}, data)
    newData.translations.push(DEFAULT_TRANSLATION)
    setData(newData)
  }

  const saveData = () => {
    if (data.id) return false

    addDoc(songsRef, {...data, createdBy: currentUser.uid}).then((docRef) => {
      setData({...data, id: docRef.id})
    })
  }

  const populateTranslations = (data) => {
    let translations = []
    if (data) {
      translations.push({
        title: data.title,
        body: data.body
      })
      translations.push(...data.translations)
    }

    return translations
  }

  let translations = populateTranslations(data)

  console.log('new', data, translations, authors)

  return (
    <>
      {data.id && <Navigate to={`/songs/${data.id}/edit`} />}

      <Breadcrumbs songId={data.id} />
      <RowBig>
        <select
          className="form-select"
          onChange={onAuthorChange}
          defaultValue={(author && author.id) || undefined}
        >
          {authors &&
            [{id: null, name: '-- Select Author --' }, ...authors]
              .map(a => <option key={a.id} value={a.id} >{a.name}</option>)}
        </select>
      </RowBig>
      <div className="form-group row mb-3">
        <div className="col">
          <input
              className="form-control"
              type="text"
              onChange={onFieldChange(0)('album')}
              value={data.album}
              placeholder="Album..."
            />
        </div>
        <div className="col-3">
          <input
              className="form-control"
              type="text"
              onChange={onFieldChange(0)('year')}
              value={data.year}
              placeholder="Year..."
            />
        </div>
      </div>
      <RowBig>
        <SongTranslationsEdit
          addTranslation={addTranslation}
          index={translationIndex}
          onFieldChange={onFieldChange}
          switchTranslation={(event) => setTranslationIndex(event.target.value)}
          translations={translations}
        />
      </RowBig>
      <RowBig>
        <button
          className="btn btn-primary btn-block"
          onClick={saveData}
        >
          Save
        </button>
      </RowBig>
    </>
  )
}
