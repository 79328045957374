export const notesRegex = /\[([A-G](b|#)?((m(aj)?|M|aug|dim|sus|add|min|dom)?([2-7]|9|11|13)?)?(b|#)?([2-7])?(\/[A-G](b|#)?)?)\]|\[(.+?)\]/g
export const notesRegexSimple = /([A-G](b|#)?)?(\/)?([A-G](b|#)?)?/g
export const scaleSharp = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"]
export const scaleFlat = ["C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab", "A", "Bb", "B"]

export default class NotesParser {
  constructor (input) {
    this.input = input
    this.output = []
  }

  parse () {
    let index = 0
    let length = 0
    let text = this.input

    text.replace(
      notesRegex,
      (match, chord, p2, p3, p4, p5, p6, p7, p8, p9, p10, misc, pos, str) => {
        pos > index && this.output.push({ value: text.slice(index, pos), type: 'lyrics' })
        chord && this.output.push({ value: chord, type: 'chord' })
        misc && this.output.push({ value: misc, type: 'misc' })

        length = match.length
        index = pos + length
      }
    )

    index < text.length &&
      this.output.push({value: text.slice(index, text.length), type: 'lyrics'})

    return this
  }

  transpose (halfSteps) {
    if (halfSteps === 0 || scaleSharp % halfSteps === 0)
      return this

    this.output.forEach((item, index) => {
      if (item.type !== 'chord') return

      let chord = item.value.replace(notesRegexSimple, transposeReplacer(halfSteps))
      this.output[index].value = chord
    })

    return this
  }
}

export const transposeReplacer = (amount) => (match, note, sign, slash, bass, bassSign, pos, str) => {
  let scale = amount > 0 ? scaleSharp : scaleFlat
  let i = amount > 0 ? amount : (scale.length + amount)
  let result = ''
  let pos1 = Math.max(scaleSharp.indexOf(note), scaleFlat.indexOf(note))
  let pos2 = Math.max(scaleSharp.indexOf(bass), scaleFlat.indexOf(bass))

  if (match) {
    result = [
      note && scale[(pos1 + i) % scale.length],
      slash,
      bass && scale[(pos2 + i) % scale.length]
    ].join('')
  }

  return result
}

