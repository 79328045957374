import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { useStorageTask } from 'reactfire';
import { getDownloadURL, getStorage, listAll, ref, uploadBytesResumable } from 'firebase/storage';
import { faFile, faFileAudio, faFileImage, faFilePdf, faFileVideo } from '@fortawesome/free-solid-svg-icons';

export const buildAuthorDocId = (author) => {
  if (author) {
    if (author.id) {
      return ['authors', author.id].join('/')
    }
    return author;
  }
  return 'authors/NO_AUTHOR';
}

export const deepSetValue = (obj, path, value) => {
  const keys = path.split('.')
  const lastKey = keys.pop()
  const lastObj = keys.reduce((obj, key) => obj[key] = obj[key] || {}, obj)
  lastObj[lastKey] = value
}

export const makeKey = (key = '') => `${key}${Math.floor(Math.random() * 1000000)}`
export const MiscTag = ({data}) => <span className="note-misc" data-misc={data}>{data}</span>
MiscTag.propTypes = {
  data: PropTypes.string.isRequired
}
export const ChordTag = ({data}) => <span className="note-chord" data-chord={data}>{data}</span>
ChordTag.propTypes = {
  data: PropTypes.string.isRequired
}

export const LyricsTag = ({data}) => <span className="note-lyrics">{data}</span>
LyricsTag.propTypes = {
  data: PropTypes.string.isRequired
}
export const strRowsRegex = /^.*((\r\n|\n|\r)|$)/gm

export const splitRows = (text = '') =>
  text.match(strRowsRegex, (match) => match.trim())

export const RowBig = ({children}) => {
  return(
    <div className="row">
      <div className="col mb-3">
        {children}
      </div>
    </div>
  )
}
RowBig.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
export const populateTranslations = (data) => {
  let translations = []
  if (data) {
    translations.push({ ...data })
    translations.push(...data.translations)
  }

  return translations
}

export const iconForContentType = (type) => {
  return {
    "image/gif": faFileImage,
    "image/png": faFileImage,
    "image/jpeg": faFileImage,
    "image/pdf": faFilePdf,
    "audio/mpeg": faFileAudio,
    "audio/wav": faFileAudio,
    "video/mpeg": faFileVideo,
  }[type] || faFile
}

export function Uploader({path, onUpload}) {
  const storage = getStorage();
  const [file, setFile] = useState(null)
  const storageRef = ref(storage, `${path}/${file && file.name}`);
  const [uploadTask, setUploadTask] = useState()

  useEffect(()=> {
    if(file) {
      setUploadTask(uploadBytesResumable(storageRef, file));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  useEffect(() => {
    if (uploadTask) {
      uploadTask.on("state_changed",
        (snapshot) => {
          console.log(snapshot.bytesTransferred, snapshot.totalBytes)
        },
        (error) => { console.error(error); },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((_downloadURL) => {
            listAll(ref(storage, path)).then((res) => onUpload(res.items) );
            setFile(null);
            setUploadTask(null);
        });
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadTask])

  const onChange = (event) => {
    event.preventDefault()
    const file = event.target.files[0]

    if (!file) return;

    setFile(file)
  }

  return(
    <>
      {uploadTask &&
        <UploadProgress
          storageRef={storageRef}
          uploadTask={uploadTask}
        />}
      <input
        className="form-control"
        type="file"
        onChange={onChange}
      />
    </>
  )
}
Uploader.propTypes = {
  path: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired
}

function UploadProgress({ storageRef, uploadTask }) {
  const { status, data: uploadProgress } = useStorageTask(uploadTask, storageRef);

  let percentComplete;

  if (status === 'loading') {
    percentComplete = '0%';
  } else {
    const { bytesTransferred, totalBytes } = uploadProgress;
    percentComplete = Math.round(100 * (bytesTransferred / totalBytes)) + '%';
  }

  return <span className='input-group-text'>{percentComplete} uploaded</span>;
}
UploadProgress.propTypes = {
  storageRef: PropTypes.object.isRequired,
  uploadTask: PropTypes.object.isRequired
}
