import React from 'react'
import { doc } from 'firebase/firestore'
import { useFirestore, useFirestoreDocData } from 'reactfire'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltV, faLanguage, faMusic, faTrash } from '@fortawesome/free-solid-svg-icons'

export default function SongEdit(props) {
  const ref = React.createRef()
  const { id, translationIndex, transpose, index, onChange, onDelete,
    onDragStart, onDragEnter, onDragEnd } = props
  const docRef = doc(useFirestore(), 'songs', id)
  const {data: docData} = useFirestoreDocData(docRef)
  const { title } = docData

  return(
    <div
      ref={ref}
      className='input-group mb-2'
      onDragStart={onDragStart(ref)}
      onDragEnter={onDragEnter(ref)}
      onDragEnd={onDragEnd(ref)}
    >
      <span
        className='input-group-text'
        onMouseDown={(e) => ref.current.draggable = true }
        onMouseUp={(e) => ref.current.draggable = false }
      >
        <FontAwesomeIcon icon={faArrowsAltV} />
      </span>
      <input
        className='form-control'
        value={title}
        readOnly={true}
      />
      <span className='input-group-text'>
        <FontAwesomeIcon icon={faLanguage} />
      </span>
      <input
        className='form-control'
        onChange={onChange(`songs.${index}.translationIndex`)}
        type='number'
        name='translationIndex'
        value={translationIndex || 0}
        style={{flex: 'none', width: '60px'}}
      />
      <span className='input-group-text'>
        <FontAwesomeIcon icon={faMusic} />
      </span>
      <input
        className='form-control'
        onChange={onChange(`songs.${index}.transpose`)}
        type='number'
        name='transpose'
        value={transpose || 0}
        style={{flex: 'none', width: '60px'}}
      />
      <span className='input-group-text'>
        <FontAwesomeIcon icon={faTrash} onClick={onDelete} />
      </span>
    </div>
  )
}
