import React from 'react'

import * as Lyrics from './lyrics'
import SwitchTranslations from './SwitchTranslations'
import CopyToClipboard from './CopyToClipboard'

const SongTranslations = ({ translations, displayTypes, transposeAmount, index, switchTranslation }) => {
  let data = translations[index || 0]

  return (
    <>
      <SwitchTranslations
        translations={translations}
        onChange={switchTranslation}
        index={index}
      >
        <CopyToClipboard text={data.body} />
      </SwitchTranslations>
      <Lyrics.Show
        text={data.body || ''}
        halfSteps={transposeAmount}
        displayTypes={displayTypes}
      />
    </>
  )
}
export default SongTranslations
