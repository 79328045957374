import React, { useEffect, useState } from 'react'

import { getStorage, ref, getDownloadURL, getMetadata, deleteObject } from 'firebase/storage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Uploader, iconForContentType } from '../utils/Utils';
import { useFileRefs } from '../utils/hooks';

function FilesEdit({id}) {
  const fileRefs = useFileRefs(id)
  const [refs, setRefs] = useState(fileRefs);

  useEffect(()=> {
    if(fileRefs.length > 0) {
      setRefs(fileRefs);
    }
  }, [fileRefs])

  if (fileRefs == null) return null;

  const onUpload = (refs) => { setRefs(refs) }

  return(
    <>
      <ul className="list-group">
        {refs.map((item, index) => <Item item={item} key={`file-${index}`} />)}
        <Uploader path={`songs/${id}/files`}
                  onUpload={onUpload} />
      </ul>
    </>
  )
}

function Item({item}){
  const storage = getStorage();
  const [fileRef, setFileRef] = useState(ref(storage, item))
  const [metadata, setMetadata] = useState({})
  const [url, setUrl] = useState()

  useEffect(() => {
    if (fileRef) {
      getMetadata(fileRef).then(metadata => setMetadata(metadata))
      getDownloadURL(fileRef).then(url => setUrl(url))
    } else {
      setUrl('')
      setMetadata({})
    }
  }, [fileRef])

  if (fileRef == null) return null;
  if (url == null) return null;

  const onItemDelete = (event) => {
    deleteObject(fileRef).then(() => {
      console.log('deleted', metadata.name, url)
      setFileRef(null);
    })
  }

  return(
    <li className="list-group-item">
      <a href={url}>
        <FontAwesomeIcon icon={iconForContentType(metadata.contentType)} />
        <span className='ms-1'>{metadata.name || url}</span>
      </a>
      <button className='btn btn-sm btn-danger float-end' onClick={onItemDelete}>X</button>
    </li>)
}

export default FilesEdit
