import React from 'react'
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useAuth, useSigninCheck } from 'reactfire';

export const signOut = auth => auth.signOut().then(() => console.log('signed out'));

const signIn = async auth => {
  const provider = new GoogleAuthProvider();

  await signInWithPopup(auth, provider);
}

export const SignInForm = () => {
  const auth = useAuth();

  return (
    <button
      className="btn btn-primary"
      label="Sign in with Google"
      onClick={() => signIn(auth)}
    >
      Sign-in with Google
    </button>
  );
};

export const AuthWrapper = ({ children, fallback }) => {
  const { data: signInCheckResult } = useSigninCheck();

  if (!children) {
    throw new Error('Children must be provided');
  }

  if (signInCheckResult.signedIn === true) {
    return children
  } else {
    return fallback;
  }
};
