import React from 'react'

import { useAuth, useUser } from 'reactfire';
import { AuthWrapper, SignInForm, signOut } from '../components/Auth';
import UserInfo from '../components/UserInfo'

export default function Login() {
  const auth = useAuth()
  const { data: currentUser } = useUser()

  return(
    <div className="row">
      <div className="col">
        <AuthWrapper fallback={<SignInForm/>}>
          <UserInfo
              user={currentUser}
              onClick={()=> signOut(auth)}
            />
        </AuthWrapper>
      </div>
    </div>
  )
}
