import React, { Fragment, useState } from 'react'

import SongEdit from './SongEdit'

export default function SongsList({items, onChange, onItemsChanged}) {
  let [draggedIndex, setDraggedIndex] = useState(null)
  let [songs, setSongs] = useState(items)

  const onDeleteItem = (id) => (event) => {
    if (!window.confirm('Delete this song from playlist?')) return

    setSongs(songs.filter(item => item.id !== id))
  }

  const onDragStart = (index) => (ref) => (event) => {
    let img = new Image()
    // Hide default drag image of dragged element, display empty gif instead
    img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
    event.dataTransfer.setDragImage(img, 0, 0)
    event.dataTransfer.effectAllowed = "link"
    setDraggedIndex(index)
  }

  const onDragEnter = (index) => (ref) => (event) => {
    event.preventDefault()

    if (draggedIndex === index) return

    let song = songs[draggedIndex]
    let newSongs = []

    if (draggedIndex > index) {
      newSongs = [
        ...songs.slice(0, index),
        song,
        ...songs.slice(index, draggedIndex),
        ...songs.slice(draggedIndex + 1)
      ]
    } else {
      newSongs = [
        ...songs.slice(0, draggedIndex),
        ...songs.slice(draggedIndex + 1, index + 1),
        song,
        ...songs.slice(index + 1),
      ]
    }

    setSongs(newSongs)
    setDraggedIndex(index)
    onItemsChanged(newSongs)
  }

  const onDrop = (index) => (ref) => (event) => event.dataTransfer.dropEffect = 'none'

  const onDragEnd = (index) => (ref) => (event) => {
    // event.preventDefault()
    event.dataTransfer.dropEffect = 'none'
    ref.current.draggable = false
    setDraggedIndex(null)
  }

  return(
    <Fragment>
      {songs.map((item, index) => <SongEdit
          {...item}
          id={item.songId}
          key={`songs-${index}`}
          index={index}
          onChange={onChange}
          onDelete={onDeleteItem(item.id)}
          onDragStart={onDragStart(index)}
          onDragEnter={onDragEnter(index)}
          onDragEnd={onDragEnd(index)}
          onDrop={onDrop(index)}
        />
      )}
    </Fragment>
  )
}

