import React, { Fragment } from 'react'
import { splitRows, makeKey, MiscTag, ChordTag, LyricsTag } from '../../utils/Utils'
import NotesParser from '../../utils/NotesParser'

const populateRow = ({text, displayTypes, halfSteps}) => {
  let displayLyrics = displayTypes.includes('lyrics')
  let displayNotes = displayTypes.includes('notes')
  let parser = new NotesParser(text)

  return parser.parse().transpose(halfSteps).output.map((item, index) => {
    let out
    if (displayLyrics && item.type === 'lyrics')
      out = <LyricsTag data={item.value} key={makeKey(`${item.type}-`)} />

    if (displayNotes && item.type === 'chord')
      out = <ChordTag data={item.value} key={makeKey(`${item.type}-`)} />

    if (displayNotes && item.type === 'misc')
      out = <MiscTag data={item.value} key={makeKey(`${item.type}-`)} />

    return out
  })
}

const LyricsRow = ({ text, displayTypes, halfSteps }) =>
  <div className="lyrics-row">{populateRow({text, displayTypes, halfSteps})}</div>

export default function Show ({ text, displayTypes, halfSteps }) {
  return (
    <Fragment>
      {splitRows(text)
        .map((row, index) =>
          <LyricsRow text={row} key={index} displayTypes={displayTypes} halfSteps={halfSteps} />)}
    </Fragment>
  )
}
