import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faMusic } from '@fortawesome/free-solid-svg-icons'

const ButtonToggleDisplay = ({types, text, type, icon, onClick}) => {
    let active = types.includes(type)
    let className = ["btn", "btn-outline-secondary", (active ? "active" : "")].join(' ')

    return (
      <button
        className={className}
        onClick={onClick(type)}
      >
        <FontAwesomeIcon icon={icon || faList}/>
        <span className="d-none d-sm-inline-block ms-2">{text}</span>
      </button>
    )
  }

export const ButtonToggleLyrics = ({types, onClick}) => {
  return(
    <ButtonToggleDisplay
      type="lyrics"
      text="Lyrics"
      icon={faList}
      types={types}
      onClick={onClick}
    />
  )
}

export const ButtonToggleNotes = ({types, onClick}) => {
  return(
    <ButtonToggleDisplay
      type="notes"
      text="Notes"
      icon={faMusic}
      types={types}
      onClick={onClick}
    />
  )
}
