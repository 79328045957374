import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'

const STRINGS = {
  COPY_TITLE: "Copy lyrics to clipboard",
  FALLBACK_RESULT: 'Fallback: Copying text command was ',
  FALLBACK_ERROR: 'Fallback: Oops, unable to copy',
  ASYNC_RESULT: 'Async: Copying to clipboard was successful!',
  ASYNC_ERROR: 'Async: Could not copy text: '
}

const CopyToClipboard = ({ text }) => {
  return (
    <button
      className="btn btn-outline-secondary btn-sm float-right"
      title={STRINGS.COPY_TITLE}
      onClick={() => copyTextToClipboard(text)}
    >
      <FontAwesomeIcon icon={faClipboard} />
    </button>
  )
}
export default CopyToClipboard

const fallbackCopyTextToClipboard = (text) => {
  var textArea = document.createElement("textarea")
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    var msg = successful ? 'successful' : 'unsuccessful'
    console.log(STRINGS.FALLBACK_RESULT, msg)
  } catch (err) {
    console.error(STRINGS.FALLBACK_ERROR, err)
  }

  document.body.removeChild(textArea)
}

const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }

  navigator.clipboard.writeText(text).then(
    () => console.log(STRINGS.ASYNC_RESULT),
    (err) => console.error(STRINGS.ASYNC_ERROR, err)
  )
}
