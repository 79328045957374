import React from 'react'
import { NavLink, useParams } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser, useFirestore, useFirestoreDocData } from 'reactfire'
import { doc } from 'firebase/firestore';

import Song from '../../components/playlist/Song'
import { faEdit, faGlobe, faLock } from '@fortawesome/free-solid-svg-icons';
import CopyToClipboard from '../../components/CopyToClipboard';

const LinkToEdit = ({id}) => {
  return(
    <NavLink to={`/playlists/${id}/edit`} className='btn btn-outline-secondary'>
      <FontAwesomeIcon icon={faEdit} />
    </NavLink>
  )
}

function Show(props) {
  const { id } = useParams()

  const {data: currentUser} = useUser()
  const docRef = doc(useFirestore(), 'playlists', id)
  const {data} = useFirestoreDocData(docRef, {idField: 'id'})
  const isOwner = data && currentUser && data.ownerId === currentUser.uid

  return (
    <>
      <h2 className='d-flex justify-content-between align-items-center'>
        {isOwner && <FontAwesomeIcon icon={data.isPublic ? faGlobe : faLock} />}
        {data.name}
        <div className='btn-group'>
          {isOwner && <LinkToEdit id={id}/>}
          <CopyToClipboard text={window.location.href} />
        </div>
      </h2>
      <ul className='list-group list-group-flush mt-3'>
        {data && data.songs.map((s, i) => <Song {...s} key={i}/>)}
      </ul>
    </>
  )
}

export default Show
