import { getStorage, listAll, ref } from "firebase/storage";
import { useEffect, useState } from "react";

function useListRef(path) {
  const storage = getStorage();
  const listRef = ref(storage, path)

  return listRef
}

export function useFileRefs(id) {
  const filesRef = useListRef(`songs/${id}/files`);
  const[files, setFiles] = useState([])

  useEffect(() => {

    listAll(filesRef).then((res) => setFiles(res.items))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return files;
}
