import React from 'react'
import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire'

function Row (props) {
  return(
    <li className='list-group-item d-flex align-items-center pl-0 pr-0'>
      {props.children}
    </li>
  )
}

function Song(props) {
  const { songId, translationIndex, transpose } = props
  const url = `/songs/${songId}/${translationIndex}/${transpose}`
  let docRef = doc(useFirestore(), 'songs', songId)
  let {data} = useFirestoreDocData(docRef)
  const translation = data.translations[translationIndex - 1]

  return(
    <Row>
      <a
        className='flex-grow-1'
        href={url}
      >
        {data && data.title}
        {translationIndex > 0 &&
          <div>
            <small className='text-muted'>
              {translation && translation.title}
            </small>
          </div>}
      </a>
    </Row>
  )
}

export default Song
