import React, { useMemo, useState } from 'react'

function LinksEdit({data, onChange}) {
  const DEFAULT_LINK = { title: '', url:'' }
  let links = useMemo(() => data.links || [], [data.links]);

  let [newLink, setNewLink] = useState(DEFAULT_LINK);

  const onLinkChange = (index) => (field) => (event) => {
    if (index === links.length) {
      let newLinkData = Object.assign(DEFAULT_LINK, newLink)
      newLinkData[field] = event.target.value
      setNewLink(newLinkData)
    } else {
      let newLinks = [...links]
      newLinks[index] ||= DEFAULT_LINK
      newLinks[index][field] = event.target.value
      // setLinks(newLinks)
      onChange(newLinks)
    }
  }

  const addLink = () => {
    console.log(links, newLink, [...links, newLink])
    let newLinks = [...links, newLink]
    // setLinks(newLinks)
    setNewLink(DEFAULT_LINK)
    onChange(newLinks)
  }

  const removeLink = (index) => () => {
    let newLinks = [...links]
    newLinks.splice(index - 1, 1)
    // setLinks(newLinks)
    onChange(newLinks)
  }

  return(
    [...links, newLink].map(({title, url}, index) =>
      <div key={`links-${index}`} className='input-group input-group-sm mb-1'>
        <input
          placeholder="Title..."
          onChange={onLinkChange(index)('title')}
          value={title}
          className='form-control'
        />
        <input
          placeholder="URL"
          onChange={onLinkChange(index)('url')}
          value={url}
          className='form-control'
        />
        {(index === links.length) && <button onClick={addLink} className='btn btn-sm btn-secondary'>Add</button>}
        {(index < links.length) && <button onClick={removeLink(index)} className='btn btn-sm btn-danger'>x</button>}
      </div>
    )
  )
}

export default LinksEdit
