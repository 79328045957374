import React, { Fragment } from 'react'

const LANGS = [
  { name: 'En', value: 'en' },
  { name: 'Ru', value: 'ru' },
  { name: 'Ua', value: 'uk' },
  { name: 'Es', value: 'es' },
  { name: 'Fr', value: 'fr' },
]

const LangOption = ({name, value}) =>
  <option value={value}>{name}</option>

export default function Form ({ title, body, lang, onChange }) {
  return(
    <Fragment>
      <div className="form-group row mb-3">
        <div className="col">
        <input
          className="form-control"
          type="text"
          onChange={onChange('title')}
          value={title}
          placeholder="Song title..."
        />
        </div>
        <div className="col-3">
        <select
          onChange={onChange('lang')}
          className="form-control form-select col"
          value={lang}
        >
          {LANGS.map((lang, index) =>
            <LangOption key={`langs-${lang.value}`} {...lang} />)}
        </select>
        </div>
      </div>
      <div className="form-group">
        <textarea
          style={{minHeight: '50vh'}}
          className="form-control"
          // rows={30}
          value={body}
          onChange={onChange('body')}
          placeholder="Lyrics..."
        />
      </div>
    </Fragment>
  )
}

