import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire'

import Loading from './Loading'
import { buildAuthorDocId } from '../utils/Utils';

export default function Breadcrumbs ({ songId, children }) {
  const firestore = useFirestore();
  const {data: song} = useFirestoreDocData(
    doc(firestore, "songs", songId || 'null'),
    { idField: 'id' }
  )
  // console.log(song);
  const {data: author} = useFirestoreDocData(
    doc(firestore, buildAuthorDocId(song && song.author) || 'authors/NO_AUTHOR'),
    { idField: 'id' }
  )

  return(
    <ol className="breadcrumb m-2">
      <Suspense fallback={<li className="breadcrumb-item"><Loading /></li>}>
        {author &&
          <li className="breadcrumb-item">
            <NavLink to={'/authors/' + author.id}>{author.name}</NavLink>
          </li>}
        {song && (song.album || song.year) &&
          <li className="breadcrumb-item">
            {song.album}
            {song.year && ['(', song.year, ')'].join('')}
          </li>}
        {song &&
          <li className="breadcrumb-item active" aria-current="page">
            <NavLink to={`/songs/${song.id}`}>{song.title}</NavLink>
          </li>}
        {children}
      </Suspense>
    </ol>
  )
}
