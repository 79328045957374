import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFirestore } from 'reactfire'
import { deleteDoc, doc } from 'firebase/firestore';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const Item = ({isOwner, onDelete}) => (item, index) => {
  const {id, name, songs } = item

  return(
    <li
      key={`item-${id}`}
      className='list-group-item d-flex justify-content-between align-items-center pl-0 pr-0'
    >
      <a href={`/playlists/${id}`} >
        <span>{name}</span>
        <small className="text-muted ms-3">{songs.length} songs</small>
      </a>
      {isOwner && <FontAwesomeIcon icon={faTrash} onClick={onDelete(item.id)} />}
    </li>
  )
}

export const List = (props) => {
  let {data, isOwner, children} = props
  // console.log('List', data, isOwner, children)
  const firestore = useFirestore()

  const onDeleteItem = (id) => async (event) => {
    if (!window.confirm('Delete this playlist?')) return

    data = data.filter(item => item.id !== id)

    await deleteDoc(doc(firestore, 'playlists', id));
  }

  return(
    <>
      {children}
      <ul className="list-group list-group-flush mt-3">
        {data.map(Item({isOwner:isOwner, onDelete: onDeleteItem}))}
      </ul>
    </>
  )
}
