import React from 'react';
import { NavLink } from "react-router-dom";
import { useUser } from 'reactfire';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faMusic, faUser } from '@fortawesome/free-solid-svg-icons';
import { ROUTES } from '../settings'

import { AuthWrapper } from '../components/Auth';
import { UserBar } from './UserInfo';

const NAV_ITEMS = [
  {
    icon: faUser,
    title: 'Authors',
    url: ROUTES.AUTHORS
  },
  {
    icon: faMusic,
    title: 'Songs',
    url: ROUTES.SONGS
  },
  {
    icon: faList,
    title: 'Playlists',
    url: ROUTES.PLAYLISTS
  },
]

const NavItem = ({icon, title, url}) =>
  <li className="nav-item">
    <NavLink to={url} className="nav-link">
      <FontAwesomeIcon icon={icon} />
      <span className="d-none d-sm-inline-block ms-1">{title}</span>
    </NavLink>
  </li>

function Navigation() {
  const LOGIN_LINK = {
    icon: 'lock',
    title: 'Login',
    url: ROUTES.LOGIN
  }
  const { data: currentUser } = useUser()

  return(
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <NavLink to={ROUTES.HOME} className="navbar-brand h1">
          FL
        </NavLink>

        <ul className="nav">
          {NAV_ITEMS.map((item, index) =>
            <NavItem key={`nav-${index}`} {...item} />)}
          <AuthWrapper fallback={<NavItem key='nav-login' {...LOGIN_LINK} />}>
            <li className="nav-item">
              <NavLink to={ROUTES.LOGIN} className="nav-link">
                <UserBar user={currentUser} />
              </NavLink>
            </li>
          </AuthWrapper>
        </ul>
      </div>
    </nav>
  )
}
export default Navigation
