import React, { useEffect, useMemo, useState } from 'react'

function ItemsList(props) {
  let items = useMemo(() => props.items || [], [props.items]);
  let fields = useMemo(() => props.fields || [], [props.fields]);
  let [query, setQuery] = useState('');
  let [filteredItems, setFilteredItems] = useState(items || []);

  useEffect(()=> {
    let newItems = items;
    if (query.length >= 2) {
      newItems = items.filter(filterItems(query, fields));
    }
    setFilteredItems(newItems);
  }, [fields, items, query])

  // console.log('filteredItems', filteredItems, query)

  return(
    <>
      {props.children}
      <div className="input-group mb-3">
        <input
          aria-label="Search"
          className="form-control"
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Search..."
          type="search"
        />
      </div>
      <div className="list-group list-group-flush mt-3">
        {filteredItems.map(props.renderItem(query), this)}
      </div>
    </>
  )
}
export default ItemsList

let filterItems = (query, fields) => (item) => {
  let q = query.toUpperCase()

  return fields.some((field) => {
    let value = item[field]
    return value && value.toUpperCase().includes(q)
  })
}
