import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLanguage } from '@fortawesome/free-solid-svg-icons'

const SwitchTranslations = ({ onChange, translations, index, children }) => {
  return(
    <div className="input-group mb-3">
      <div className="input-group-text">
        <FontAwesomeIcon icon={faLanguage} />
      </div>
      <select
        onChange={onChange}
        className="form-control form-select"
        value={index}
      >
      {translations.map((data, index) =>
        <option key={`translations-${index}`} value={index}>{data.title} {data.lang && `(${data.lang})`}</option>)}
      </select>
      { children }
    </div>
  )
}
export default SwitchTranslations
