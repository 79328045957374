import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"

import { FirebaseAppProvider, FirestoreProvider, AuthProvider, useFirebaseApp } from 'reactfire';

import firebaseConfig from './firebaseConfig'
import { getAuth } from 'firebase/auth';
import { initializeFirestore } from 'firebase/firestore';

import * as routes from './routes'

import Navigation from './components/Navigation'


function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
      <FirebaseComponents>
        <MyApp />
      </FirebaseComponents>
    </FirebaseAppProvider>
  );
}
export default App

function FirebaseComponents({ children }) {
  const app = useFirebaseApp(firebaseConfig);
  const db = initializeFirestore(app, {
    experimentalAutoDetectLongPolling: true,
    useFetchStreams: false,
  })
  const auth = getAuth(app);

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={db}>
        {children}
      </FirestoreProvider>
    </AuthProvider>
  );
}

function MyApp() {
  return(
    <div className="container">
      <Suspense fallback={<div className="col text-center">Loading...</div>}>
        <BrowserRouter>
          <Navigation />
          <Routes>
            <Route exact path="/" element={<routes.home />} />
            <Route exact path="/login" element={<routes.login/>} />

            <Route exact path="/authors" element={<routes.authors.search />} />
            <Route exact path="/authors/:id" element={<routes.authors.show />} />
            <Route exact path="/authors/new" element={<routes.authors.new />} />
            <Route exact path="/authors/:id/edit" element={<routes.authors.edit />} />

            <Route exact path="/songs" element={<routes.songs.search />} />
            <Route exact path="/songs/new" element={<routes.songs.new />} />
            <Route exact path="/songs/:id/edit" element={<routes.songs.edit />} />
            <Route exact path="/songs/:id" element={<routes.songs.show />} />
            <Route exact path="/songs/:id/:translation" element={<routes.songs.show />} />
            <Route exact path="/songs/:id/:translation/:transpose" element={<routes.songs.show />} />
            <Route exact path="/songs/:id/:translation/edit" element={<routes.songs.edit />} />
            <Route exact path="/songs/:id/:translation/:transpose/edit" element={<routes.songs.edit />} />

            <Route exact path="/playlists" element={<routes.playlists.list />} />
            <Route exact path="/playlists/:id" element={<routes.playlists.show />} />
            <Route exact path="/playlists/:id/edit" element={<routes.playlists.edit />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  )
}
