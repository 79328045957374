import React from 'react'
import { NavLink } from "react-router-dom"

import { collection, query, orderBy } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire'

import ItemsList from '../../components/ItemsList'
import Highlight from '../../components/Highlight'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

function Search() {
  const firestore = useFirestore();
  const collectionRef = collection(firestore, "authors")
  const queryRef = query(collectionRef, orderBy("name", "asc"))
  const { status, data } = useFirestoreCollectionData(queryRef, {idField: 'id'})

  const {data: currentUser} = useUser()

  if (status === 'loading')
  {
    return <p>Loading Authors</p>
  }

  return (
    <ItemsList
      fields={['name']}
      items={data || []}
      renderItem={renderItem}
      >
      <h2>
        Authors
        {currentUser &&
          <NavLink to="/authors/new">
            <FontAwesomeIcon icon={faPlus} className="ms-3" />
          </NavLink>}
      </h2>
    </ItemsList>
  )
}
export default Search

function renderItem (searchQuery) {
  return function (item, index) {
    return(
      <NavLink
        className="list-group-item pl-0 pr-0 d-flex"
        key={'author-' + index}
        to={`/authors/${item.id}`}
      >
        <span className="flex-grow-1">
          <Highlight text={item.name} part={searchQuery} />
        </span>
      </NavLink>
    )
  }
}

