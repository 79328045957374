import React from 'react'
import { NavLink, useParams } from "react-router-dom"
import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ListSongs from '../../components/ListSongs'
import { faChevronLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
import { RowBig } from '../../utils/Utils';

function Show(props) {
  const params = useParams()
  const { id } = params
  const docRef = doc(useFirestore(), "authors", id)
  const { status, data } = useFirestoreDocData(docRef, { idField: 'id' })

  if (status === 'loading') {
    return <span>Loading...</span>;
  }

  return (
    <>
      <RowBig>
        <div className="col">
          <h2>
            <NavLink to="/authors" className="me-3">
              <FontAwesomeIcon icon={faChevronLeft} />
            </NavLink>
            {data && data.name}
            <NavLink to={`/authors/${data.id}/edit`} className="ms-3">
              <FontAwesomeIcon icon={faEdit} />
            </NavLink>
          </h2>
          <div>
            {data && data.description}
          </div>
        </div>
      </RowBig>
      <hr/>
      <RowBig>
        <div className="col mb-3">
          {data && <ListSongs authorId={id} />}
        </div>
      </RowBig>
    </>
  )
}

export default Show
