import React, { useState } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { useUser, useFirestore, useFirestoreDocData } from 'reactfire'
import { doc } from 'firebase/firestore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SongTranslations from '../../components/SongTranslations'
import Breadcrumbs from '../../components/Breadcrumbs'
import { ButtonToggleLyrics, ButtonToggleNotes } from '../../components/ButtonToggleDisplay'
import ButtonsTranspose from '../../components/ButtonsTranspose'
import { RowBig } from '../../utils/Utils'
import AddToPlaylist from '../../components/playlist/AddToPlaylist'
import FilesList from '../../components/FilesList';
import { faEdit, faLink } from '@fortawesome/free-solid-svg-icons';

function Show() {
  const { id, translation, transpose } = useParams();
  const { data: currentUser } = useUser()
  const docRef = doc(useFirestore(), "songs", id)
  const { data } = useFirestoreDocData(docRef, {idField: 'id'})

  // TODO: add proper admin check
  const isAdmin = currentUser

  let translations = [],
    [displayTypes, setDisplayTypes] = useState(['lyrics']),
    [transposeAmount, setTransposeAmount] = useState(Number(transpose) || 0),
    [translationIndex, setTranslationIndex] = useState(translation || Math.min(translations.length, 1))

  const toggleDisplayType = (type) => (event) => {
    let newTypes = [...displayTypes, type]

    if (displayTypes.includes(type)) {
      newTypes = displayTypes.filter((item) => item !== type)
    }

    if (newTypes.length === 0) newTypes.push('lyrics')

    setDisplayTypes(newTypes)
  }

  const incTransposeAmount = (amount) => (event) => setTransposeAmount((transposeAmount + amount) % 12)

  translations = [{
    title: `${data.title} (original)`,
    body: data.body
  }]
  translations.push(...data.translations)

  console.log('Songs.Show()', data);

  return (
    <>
      <Breadcrumbs songId={id} />
      <RowBig>
        <div className="btn-toolbar">
          <div className="btn-group">
            <ButtonToggleLyrics types={displayTypes} onClick={toggleDisplayType} />
            <ButtonToggleNotes types={displayTypes} onClick={toggleDisplayType} />
          </div>

          {displayTypes.includes('notes') &&
            <ButtonsTranspose
              value={transposeAmount}
              onClick={incTransposeAmount}
            />}

          {isAdmin &&
            <NavLink to={`edit`} className="btn btn-outline-secondary ms-3">
              <FontAwesomeIcon icon={faEdit} />
              <span className="d-none d-sm-inline-block ms-1">Edit</span>
            </NavLink>}

          {isAdmin && <AddToPlaylist
            songId={id}
            transpose={transposeAmount}
            translationIndex={translationIndex}
          />}
        </div>
      </RowBig>
      <RowBig>
        <SongTranslations
          displayTypes={displayTypes}
          transposeAmount={transposeAmount}
          index={translationIndex}
          switchTranslation={(event) => setTranslationIndex(event.target.value)}
          translations={translations}
        />
      </RowBig>

      <RowBig>
        <Links links={data.links} />
      </RowBig>

      <RowBig>
        <FilesList id={id} />
      </RowBig>
      <RowBig>
        <small className='text-muted'>
        createdBy: {data.createdBy || 'anonymous' }
        /
        updatedBy: {data.updatedBy || 'anonymous' }
        </small>
      </RowBig>

    </>
  )
}

export default Show

const Links = ({links}) => {
  if (links === undefined) return null

  return(
    <ul>
      {links.map((link, index) => <Link {...link} key={`link-${index}`} />)}
    </ul>
  )
}

const Link = ({title, url}) =>
  <li>
    <a href={url}>
      <FontAwesomeIcon icon={faLink} />
      <span className='ms-1'>{title || url}</span>
    </a>
  </li>
